<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="p-3 md:p-6 w-full max-md:h-full max-md:overflow-auto hide-scrollbar">
        <div class="flex justify-between mt-10 mb-16 md:mt-0 w-full md:mb-1 ">
            <div class="flex w-2/3">
                <div @click="filter = true" class="w-1/2 flex mr-3 justify-center cursor-pointer"
                    :class="{ 'opacity-30': !filter }">
                    <svg class="mr-1 overflow-visible w-5" style="" width="19" height="21" viewBox="0 0 19 21" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.423 21C7.9353 21 7.45775 20.8817 7.01069 20.6451C6.11657 20.162 5.57807 19.2845 5.57807 18.2986V13.0732C5.57807 12.5803 5.24278 11.8408 4.92781 11.4662L1.12781 7.56197C0.4877 6.94084 0 5.87606 0 5.07746V2.80986C0 1.23239 1.22941 0 2.79412 0H16.2059C17.7503 0 19 1.21268 19 2.71127V4.88028C19 5.91549 18.3599 7.08873 17.7604 7.67042L13.361 11.4465C12.9342 11.7915 12.5989 12.5507 12.5989 13.162V17.4014C12.5989 18.2789 12.03 19.2944 11.3187 19.7084L9.91658 20.5859C9.45936 20.862 8.94118 21 8.423 21ZM2.79412 1.47887C2.08289 1.47887 1.52406 2.06056 1.52406 2.80986V5.07746C1.52406 5.44225 1.82888 6.15211 2.21497 6.52676L6.08609 10.4803C6.60428 11.1014 7.1123 12.1366 7.1123 13.0634V18.2887C7.1123 18.9296 7.56952 19.2451 7.76257 19.3437C8.18931 19.5704 8.70749 19.5704 9.10375 19.3338L10.516 18.4563C10.8005 18.2887 11.085 17.7563 11.085 17.4014V13.162C11.085 12.107 11.6134 10.9437 12.3754 10.3225L16.7241 6.58592C17.0695 6.2507 17.4861 5.45211 17.4861 4.87042V2.71127C17.4861 2.03099 16.9171 1.47887 16.216 1.47887H2.79412Z"
                            fill="white" />
                    </svg>
                    <div class="text-[#ffffff] ml-1 text-base font-medium ">
                        Filters
                    </div>
                </div>
                <div class="w-px h-[26px] bg-white opacity-30 rounded-md"></div>
                <div @click="filter = false" class="w-1/2 p-0 pl-[5px] flex justify-center cursor-pointer"
                    :class="{ 'opacity-30': filter }">
                    <div class="text-[#ffffff]  whitespace-nowrap text-base font-normal">
                        Sort by
                    </div>
                </div>
            </div>
            <div>
                <svg @click="$emit('close')" class=" cursor-pointer overflow-visible" width="22" height="22"
                    viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.6975 17.5287L4.94869 5.77989C4.68108 5.51228 4.61943 5.13008 4.81271 4.93681C5.00598 4.74353 5.38818 4.80517 5.6558 5.07279L17.4046 16.8216C17.6723 17.0893 17.7339 17.4715 17.5406 17.6647C17.3474 17.858 16.9652 17.7964 16.6975 17.5287Z"
                        fill="white"></path>
                    <path
                        d="M17.1396 5.69162L5.39073 17.4405C5.12312 17.7081 4.74092 17.7697 4.54765 17.5765C4.35437 17.3832 4.41601 17.001 4.68363 16.7334L16.4325 4.98451C16.7001 4.7169 17.0823 4.65526 17.2756 4.84853C17.4688 5.04181 17.4072 5.42401 17.1396 5.69162Z"
                        fill="white"></path>
                </svg>
            </div>
        </div>
        <div v-if="filter" class="filters max-md:h-full overflow-auto hide-scrollbar">
            <div class="mt-3" v-for="(item, index) in filterData" :key="index">
                <component :is="item.type" @updated="updateData" :keyName="index" @showItem="showItem" :show="show[index]"
                    :key="index" :item="item" />
            </div>
        </div>
        <div v-else class="sort-by w-full flex gap-2 mt-4 overflow-auto hide-scrollbar">
            <div class="flex-grow">
                <input type="checkbox" v-model="sortName" @change="$emit('handleSortType', sortName)" id="toggle"
                    class="toggleCheckbox" />
                <label for="toggle" class='toggleContainer bg-[#2A2A2A]  w-full'>
                    <div>
                        <p class="p-2 md:p-1.5 m-0 text-base font-normal">
                            Name
                        </p>
                    </div>
                    <div>
                        <p class="p-2 md:p-1.5 m-0  text-base font-normal">
                            Size
                        </p>
                    </div>
                </label>
            </div>
            <div class="flex-grow">
                <input type="checkbox" v-model="sortStyle" @change="$emit('handleSortStyle', sortStyle)" id="toggle2"
                    class="toggleCheckbox" />
                <label for="toggle2" class='toggleContainer bg-[#2A2A2A] w-full'>
                    <div>
                        <p class="p-2 md:p-1.5 m-0 text-base font-normal">
                            Asc
                        </p>
                    </div>
                    <div>
                        <p class="p-2 md:p-1.5 m-0  text-base font-normal">
                            Desc
                        </p>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script>
import { ref, onMounted } from 'vue'
import ItemSelection from './filters/itemSelection.vue'
import RangeSelection from './filters/rangeSelection.vue'

export default {
    name: 'filterPage',
    components: {
        ItemSelection,
        RangeSelection
    },
    mounted() {
    },
    props: {
        filterData: [Object, Array]
    },
    setup(props, { emit }) {
        const filterData = ref(props.filterData)
        const show = ref({})

        onMounted(() => {
            filterData.value = props.filterData
            for (const key in filterData.value) {
                if (Object.hasOwnProperty.call(filterData, key)) {
                    const element = filterData[key];
                    show.value[key] = false
                }
            }
            console.log('show.value', show.value);
        })

        const updateData = (e) => {
            if (e.name) {
                filterData.value[e.name].selected = e.data
                emit('updateFilter', e)
            }
        }


        const showItem = (e) => {
            console.log('show', show.value);
            for (const key in show.value) {
                if (Object.hasOwnProperty.call(show.value, key)) {
                    const element = show.value[key];
                    show.value[key] = false
                }
            }
            show.value[e[0]] = !e[1]
        }
        const filter = ref(true)
        const sortStyle = ref(false)
        const sortName = ref(true)
        const f = ref([false, false, false, false, false, false, false])

        return { filter, sortStyle, sortName, f, updateData, filterData, show, showItem }
    }
}
</script>

<style lang="scss" scoped>
.filters {
    height: calc(70vh - 4.625rem);
}

@media screen and (max-width: 768px) {
    .filters {
        height: calc(100vh - 4.625rem);
    }
}

.sort-by {
    height: fit-content;
}</style>
