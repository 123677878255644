<template>
  <div class="mt-3" v-if="props.item">
      <div class="bg-white/10 flex w-full md:max-w-[336px] flex-col items-stretch pl-3 pr-6 py-5 !pt-0 overflow-hidden rounded-2xl hide-scrollbar transition-all duration-300 ease-in"
          :class="{ 'max-h-[6rem]': !show && item.selected.length > 0, 'max-h-[3.75rem]': !show && item.selected.length == 0, 'max-h-[25rem]': show }">
          <div class="flex w-full items-stretch justify-between gap-5 pt-5 cursor-pointe" 
               @click="$emit('showItem', [props.keyName, show])">
              <div class="flex items-center justify-between gap-2">
                  <div
                      class="border bg-transparent flex w-4 shrink-0 h-4 flex-col rounded-md border-solid border-op-white-100">
                  </div>
                  <div class="text-op-white-800 text-sm self-center my-auto">{{ props.item.name }}</div>
              </div>
              <img loading="lazy" :class="{'rotate-180': show}"
                  src="@/assets/icons/svg/floorplans/arrow-down.svg"
                  class=" w-[13px] h-[20px] stroke-[1px] transition-all duration-300 ease-in-out stroke-white overflow-hidden self-center shrink-0 max-w-full my-auto" />
          </div>
          <div class="flex flex-wrap items-start transition-all duration-300 overflow-auto hide-scrollbar gap-2 mt-3"
          :class="{'p-[2px]': show}">
              <div v-for="(item, index) in props.item.data" :key="index" @click="selectItem(item)"
                 :class="{' border-primary-1000 shadow-my text-white shadow-primary-300': selected.includes(item),
                          ' text-op-white-300 border-op-white-300': !selected.includes(item),
                          ' block px-3 py-3 text-center rounded-[50%] border-solid w-[48px] h-[48px]': item.length <= 1 || !item.length,
                          ' grow justify-center items-stretch px-4 py-4 text-center rounded-[32px] border-solid': item.length > 1}"
                  class=" text-sm cursor-pointer whitespace-nowrap border bg-white bg-opacity-0">
                  {{ item }}
              </div>
          </div>
          <div v-if="!show && item.selected.length > 0" class="text-sm pt-1.5 pl-[1.5rem] text-op-white-200">
            {{ item.selected.join(', ').length > 33 ? item.selected.join(', ').substring(0, 33) + '...' : item.selected.join(', ') }}
          </div>
      </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted } from 'vue'
import useEmitter from '@/composable/useEmitter'

const selected = ref([])
// const show = ref(false)
const props = defineProps({
show: {
  type: Boolean,
  default: false
},
item: {
  type: Object
},
text: {
  type: String,
  default: 'button'
},
keyName: {
  type: String,
  default: 'button'
},
classList: {
  type: [String],
  default: 'px-9'
}
})

onMounted(() => {
selected.value = props.item.selected
})

const emit = defineEmits(['updated', 'showItem'])

const selectItem = (e) => {
if (selected.value.includes(e)) {
  selected.value = selected.value.filter(word => word !== e)
} else {
  selected.value.push(e)
}
emit('updated', { name: props.keyName, data: selected.value })
}
</script>
