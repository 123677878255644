import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import AppToast from "@/components/base/AppToast.vue";
import IconToastSuccess from "@/components/svg/toast-icons/IconToastSuccess.vue";
import IconToastError from "@/components/svg/toast-icons/IconToastError.vue";
import IconToastWarning from "@/components/svg/toast-icons/IconToastWarning.vue";

export function showToast({ message, type }) {
  const icon =
    type === "success"
      ? IconToastSuccess
      : type === "error"
        ? IconToastError
        : IconToastWarning;

  toast(AppToast, {
    theme: "dark",
    closeOnClick: false,
    data: {
      message,
    },
    position: toast.POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    closeButton: false,
    type,
    icon: icon,
  });
}
