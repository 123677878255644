mode<template>
    <div class="wrapper">
        <div class="icon-search-container mx-5 w-[60px] h-[60px]" :class="[ isSearchActive ? 'active' : '', classList ]">
            <div class="flex justify-center items-center w-[60px] h-[60px]" @click="activateSearch">
                <img :src="require('@/assets/icons/svg/'+ icon)" alt="Search" />
            </div>
            <input v-model="searchInput" type="text" class="h-14 search-input text-base "
            :class="[isSearchActive ? 'active' : '', placeholderColor]" :placeholder="placeholder" @keyup="$emit('search' , searchInput)"/>
        </div>
    </div>
</template>

<script setup>

import { ref } from 'vue'

// eslint-disable-next-line no-undef
defineProps({
  classList: {
    type: String,
    default: 'bg-qooWhite-5'
  },
  placeholderColor: {
    type: String,
    default: 'placeholder-mainBlack-300'
  },
  icon: {
    type: String,
    default: 'floorplans/search.svg'
  },
  placeholder: {
    type: String,
    default: 'Search by location'
  }
})
const isSearchActive = ref(false)
const searchInput = ref('')
const emit = defineEmits(['activateSearch'])

const activateSearch = () => {
  isSearchActive.value = !isSearchActive.value
  emit('activateSearch', isSearchActive.value)
}

</script>

<style scoped>

.icon-search-container {
    display: inline-block;
    border-radius: 50px;
    position: relative;
    transition: width 0.8s ease-out;
    backface-visibility: hidden;
}

.icon-search-container.active {
    width: 285px;
}

@media screen and (max-width : 768px) {
    .icon-search-container.active {
        width: 230px;
    }
}

.icon-search-container.active .fa-times-circle {
    opacity: 1;
}

.icon-search-container.active .search-input {
    width: 200px;
}

.fa-search {
    color: #2980b9;
    font-size: 30px;
    position: absolute;
    top: 7px;
    left: 8px;
    cursor: pointer;
}

.fa-times-circle {
    opacity: 0;
    color: #d9d9d9;
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 8px;
    transition: opacity 0.8s ease-out;
    cursor: pointer;
}

.search-input {
    position: absolute;
    cursor: default;
    left: 55px;
    top: 0px;
    width: 0;
    padding: 5px;
    border: none;
    outline: none;
    line-height: 20px;
    background-color: rgba(255, 255, 255, 0);
    transition: width 0.7s ease-out;
    /* transition-delay: 0.3s; */
    transition-delay: unset !important;

}

.search-input.active {
    /* transition-delay: unset !important; */
    transition-delay: 0.3s;

}
</style>
