export const jsonFilterData = {
  type: {
    name: 'Type',
    type: 'ItemSelection',
    data: ['1 Bedroom', '1 Bedroom + Den', '2 Bedroom', '2 Bedroom + Den', '3 Bedroom', '3 Bedroom + Den'],
    selected: []
  },
  area: {
    name: 'Area (sq.ft.)',
    type: 'RangeSelection',
    data: [400, 1500],
    selected: []
  },
  bathroom: {
    name: 'Bathroom',
    type: 'ItemSelection',
    data: [1, 2, 3, 4],
    selected: []
  },
  orientation: {
    name: 'Orientation',
    type: 'ItemSelection',
    data: ['S', 'W', 'N', 'E'],
    selected: []
  },
  floor: {
    name: 'Floor',
    type: 'RangeSelection',
    data: [1, 40],
    selected: []
  }
}
