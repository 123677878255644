<template>
    <Transition>
        <div class="box-modal w-72 hide-scrollbar" :class="{ 'h-500' : hoverdUntiDetail.floor_plan, 'h-400' : !hoverdUntiDetail.floor_plan  }" v-show="hoverdUntiDetail && Object.keys(hoverdUntiDetail).length">
            <div class="status-tag" :class="{ 'has-close' : closeable }" v-if="hoverdUntiDetail.status !== 'Conditional'">
                <span class="status-color">
                    <span :style="{ backgroundColor: setStatusColor(hoverdUntiDetail.status) }"></span>
                </span>
                {{ hoverdUntiDetail.status }}
            </div>
            <div class="closeable" @click="$emit('close', true)" v-if="closeable">
                &times;
            </div>
            <div class="box-modal-header border-b p-6 flex items-center">
                <h4 class="text-base text-white mr-2">{{ hoverdUntiDetail.name }}</h4>
                <p class="text-sm text-white"><span class="mr-1">|</span> {{ hoverdUntiDetail.model_name }}</p>
            </div>
            <div class="bg-white p-2 w-11/12 mx-auto mt-3">
                <img v-if="hoverdUntiDetail.floor_plan" loading="lazy" :src="hoverdUntiDetail.floor_plan.image"
                class="h-auto w-auto aspect-square mx-auto overflow-hidden rounded-lg" />
            </div>
            
            <div class="box-modal-body p-6">
                <ul class="flex flex-wrap items-between">
                    <li class="w-1/2 mb-10">
                        <span class="title text-xs text-white opacity-40 block">Type</span>
                        <span class="text-sm text-white">{{ hoverdUntiDetail.suite_style || '-' }}</span>
                    </li>
                    <li class="w-1/2 mb-10">
                        <span class="title text-xs text-white opacity-40 block">Area</span>
                        <span class="text-sm text-white">{{ (hoverdUntiDetail.area ? hoverdUntiDetail.area + ' sq.ft.' : '-') }}</span>
                    </li>
                    <li class="w-1/2 mb-10">
                        <span class="title text-xs text-white opacity-40 block">Bathrooms</span>
                        <span class="text-sm text-white">{{ hoverdUntiDetail.bathroom || '-' }}</span>
                    </li>
                    <li class="w-1/2 mb-10">
                        <span class="title text-xs text-white opacity-40 block">Orientation</span>
                        <span class="text-sm text-white">{{ hoverdUntiDetail.exposure || '-' }}</span>
                    </li>
                    <li class="w-1/2">
                        <span class="title text-xs text-white opacity-40 block">Floor</span>
                        <span class="text-sm text-white">{{ hoverdUntiDetail.floor?.name || '-' }}</span>
                    </li>
                    <li class="w-1/2">
                        <span class="title text-xs text-white opacity-40 block">Price</span>
                        <span class="text-sm text-white">{{ hoverdUntiDetail.price ? '$' + hoverdUntiDetail.price.toLocaleString('en-US') : '-' }}</span>
                        <!-- <span class="text-sm text-white">-</span> -->
                    </li>
                </ul>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
  hoverdUntiDetail: {
    type: [Object, String],
    required: true
  },
  closeable: {
    type: Boolean,
    required: false
  }
})

const setStatusColor = (status) => {
    let color = '';
    switch (status) {
        case 'Available':
            color = '#1BCE6E';
        break;
        case 'Sold':
            color = '#CE461B';
        break;
        case 'Unavailable':
            color = '#7A7A7A';
        break;
        case 'Allocated':
            color = '#2460E6';
        break;
        case 'Reserved':
            color = '#5D4DC1';
        break;
    }

    return color;
}
</script>

<style lang="scss">
.main-3d{
  .box-modal{
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(16px);
    position: fixed;
    .status-tag{
        padding: 6px 15px;
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        width: max-content;
        background-color: #757575;
        margin-left: 15px;
        margin-top: -20px;
        position: absolute;
        &.has-close {
            position: unset;
            margin-top: 20px;
        }
        .status-color{
            display: inline-block;
            border: 2px solid #828282;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            margin-right: 10px;
            span{
                border-radius: 50%;
                display: inline-block;
                width: 10px;
                height: 10px;
                border: 2px solid #fff;
                float: left;
            }
        }

    }
    &.h-500{
        height: 500px;
    }
    &.h-400{
        height: 400px;
    }
    .bg-white{
        img{
            max-height: 150px;
        }
    }
    .box-modal-header{
        border-color: rgba(255, 255, 255, 0.2);
    }
    .closeable {
        text-align: right;
        font-size: 28px;
        padding-right: 20px;
        color: #fff;
        width: max-content;
        float: right;
        margin-top: -40px;
        cursor: pointer;
    }
  }
}
</style>
