<template>
  <div class="flex relative justify-between items-center">
    <div class="flex items-center md:w-fit w-full md:justify-start justify-between">
      <div>
        <qooBtn type="mainBlack" text="Filters, Sort by" :paddingStyle="'py-[20px] px-[24px]'" :class="{ invisible: isFilterActive }" icon="filter.svg"
          :showText="!activeSearch" @clickBtn="showFilter" />
      </div>
      <div v-if="mode !== '3d'">
        <searchInput @activateSearch="activateSearch" @search="searchModels($event)" placeholder="Search by model name"></searchInput>
      </div>
    </div>
    <div  :class="{ slideIn: isFilterActive, slideOut: !isFilterActive, [filterTop]: true }"
      class="z-10 backdrop-blur-md filter-container absolute left-0 bg-stone-1000/80 hide-scrollbar 
             !hidden md:!flex h-fit max-h-[70vh] overflow-hidden max-w-[384px] w-full flex-col mx-auto rounded-[30px]">
      <filterModal v-if="show" @updateFilter="updateFilter" :filterData="filterData" @close="showFilter"
        @handleSortType="$emit('handleSortType', $event)" @handleSortStyle="$emit('handleSortStyle', $event)">
      </filterModal>
    </div>
    <div  :class="{ slideIn: isFilterActive, slideOut: !isFilterActive }" class="md:!hidden z-150 backdrop-blur-md filter-container fixed top-0 left-0 pt-7 bg-stone-1000 hide-scrollbar 
             flex h-[100vh] overflow-hidden max-w-full w-full flex-col mx-auto">
      <filterModal @updateFilter="updateFilter" :filterData="filterData" @close="showFilter"
        @handleSortType="$emit('handleSortType', $event)" @handleSortStyle="$emit('handleSortStyle', $event)">
      </filterModal>
    </div>
    <div class="md:flex items-center hidden" v-if="!props.hideToggle">
      <div>
        <input type="checkbox" v-model="checkbox" @change="$emit('handleCheckboxChange', checkbox)" id="toggle3"
          class="toggleCheckbox" />
        <label for="toggle3" class="toggleContainer bg-qooWhite-5">
          <div>
            <img class="p-2 md:p-[8px]" src="@/assets/icons/svg/floorplans/Category.svg" />
          </div>
          <div>
            <img class="p-2 md:p-[8px]" src="@/assets/icons/svg/floorplans/menu.svg" />
          </div>
        </label>
      </div>
      <div v-if="props.mode == 'card'">
        <div class="relative w-[8rem] h-[6.75rem]">
          <div @click="$emit('openSide')" :class="{ 'animation-icon': props.firstTime }"
            class="cursor-pointer absolute top-[22px] left-6 w-16 h-16 rounded-full border justify-center items-center backdrop-blur bg-qooWhite-60 border-white flex gap-1">
            <img src="@/assets/icons/svg/floorplans/arrow.svg" class="w-2 mr-1" />
            <img src="@/assets/icons/svg/floorplans/building.svg" class="w-5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import searchInput from '@/components/base/common/searchInput.vue'
import qooBtn from '@/components/base/common/qooBtn.vue'
import filterModal from '@/components/base/filter.vue'
import { jsonFilterData } from '@/util/filterData'

import { ref, defineProps, defineEmits, computed, onMounted, watch } from 'vue'

import useBreakpoint from '@/composable/useBreakpoint'

import { useFloorplan } from '@/store/floor.js'
let floorplan = {}

const show = ref(false)

const {
  breakpoints,
  screens: { sm }
} = useBreakpoint()

const props = defineProps({
  side: {
    type: Boolean,
    default: false
  },
  filterTop: {
    type: String,
    default: 'top-[2.25rem]'
  },
  mode: {
    type: String,
    default: 'card'
  },
  firstTime: {
    type: Boolean,
    default: false
  },
  hideToggle: {
    type: Boolean,
    default: false
  },
  models: {
    type: Array
  },
  toggleViewMode: {
    type: Boolean,
    default: false
  }
})

const filterData = ref(JSON.parse(JSON.stringify(jsonFilterData)))

const activeSearch = ref(false)
const activateSearch = (e) => {
  if (breakpoints.w < 768) activeSearch.value = e
}

const emit = defineEmits(['filterModels', 'showFilter', 'handleSortStyle', 'handleSortType', 'handleCheckboxChange'])

const floorsCount = ref([])
const searchValue = ref('')
const checkbox = ref(props.toggleViewMode)

// when the value of toggleViewMode changes, update the value of checkbox
watch(
  () => props.toggleViewMode,
  (val) => {
    checkbox.value = val
  }
)

const isFilterActive = ref(false)
const showFilter = () => {
  isFilterActive.value = !isFilterActive.value
  console.log('showFilter', isFilterActive.value );
  emit('showFilter', isFilterActive.value)
}

const searchModels = (e) => {
  searchValue.value = e
}

const filteredItems = computed(() => {
  let modelsArray = props.models

  if (
    filterData.value.type.selected.length ||
    filterData.value.bathroom.selected.length ||
    filterData.value.area.selected[0] ||
    filterData.value.area.selected[1] ||
    floorsCount.value.length ||
    filterData.value.orientation?.selected?.length
  ) {
    modelsArray = props.models.filter(
      (el) =>
        (filterData.value?.bathroom.selected.length
          ? filterData.value?.bathroom.selected.includes(el.bathroom)
          : true) &&
        (filterData.value?.type.selected.length
          ? filterData.value?.type.selected.includes(el.suite_style)
          : true) &&
        (filterData.value?.area.selected[0]
          ? +el.area >= +filterData.value?.area.selected[0]
          : true) &&
        (filterData.value?.area.selected[1]
          ? +el.area <= +filterData.value?.area.selected[1]
          : true) &&
        (filterData.value?.orientation?.selected?.length
          ? el.exposure.filter((exp) =>
            filterData.value.orientation?.selected.includes(exp)
          ).length
          : true) &&
        (floorsCount.value.length
          ? el.floors.filter((floor) => floorsCount.value.includes(floor))
            .length
          : true)
    )
  } else {
    modelsArray = props.models
  }

  if (searchValue.value !== '' && searchValue.value) {
    modelsArray = modelsArray.filter((item) => {
      return item.model_name
        .toUpperCase()
        .includes(searchValue.value.toUpperCase())
    })
  }

  return modelsArray
})

watch(filteredItems, () => {
  emit('filterModels', filteredItems.value)
})

const updateFilter = (e) => {
  switch (e.name) {
    case 'area':
      filterData.value.area.selected = e.data
      break
    case 'type':
      filterData.value.type.selected = e.data
      break
    case 'bathroom':
      filterData.value.bathroom.selected = e.data
      break
    case 'orientation':
      filterData.value.orientation.selected = e.data
      break
    case 'floor':
      filterData.value.floor.selected = e.data
      break
  }

  if (
    filterData.value.floor && 
    (filterData.value.floor.selected[0] ||
    filterData.value.floor.selected[1])
  ) {
    floorsCount.value = []
    for (
      let i = filterData.value.floor.selected[0];
      i <= filterData.value.floor.selected[1];
      i++
    ) {
      floorsCount.value.push(i)
    }
  }
}

function extractParts(roomString) {
    const parts = roomString.split(' ');
    const number = parseInt(parts[0], 10);
    const hasDen = roomString.includes('+ Den');
    return { number, hasDen };
}

function compareRooms(a, b) {
    const roomA = extractParts(a);
    const roomB = extractParts(b);

    if (roomA.number !== roomB.number) {
        return roomA.number - roomB.number;
    }

    if (roomA.hasDen && !roomB.hasDen) {
        return 1;
    } else if (!roomA.hasDen && roomB.hasDen) {
        return -1;
    }

    return 0;
}

const addFilterData = async () => {
  if (floorplan.floorplanList && floorplan.floorplanList.length !== 0) {
    const units = floorplan.floorplanList

    const types = []
    const areas = []
    const bathrooms = []
    const myFloors = []

    units.forEach(unit => {
      const { bathroom, floors, suite_style } = unit

      if (!types.includes(suite_style)) {
        types.push(suite_style)
      }

      if (!bathrooms.includes(bathroom)) {
        bathrooms.push(bathroom)
      }

      if (!areas.includes(+unit.area)) {
        areas.push(+unit.area)
      }

      if (floors) {
        floors.forEach(f => {
          if (!myFloors.includes(f)) {
            myFloors.push(f)
          }
        })
      }
    })
    types.sort(compareRooms);
    filterData.value.type.data = types
    // filterData.value.type.data = [...new Set(types)]
    filterData.value.area.data = [Math.min(...areas) - 10, Math.max(...areas) + 10]
    filterData.value.area.selected = [Math.min(...areas) - 10, Math.max(...areas) + 10]
    filterData.value.bathroom.data = bathrooms
    if (filterData.value.floor) {
      filterData.value.floor.data = [Math.min(...myFloors), Math.max(...myFloors)]
      filterData.value.floor.selected = [Math.min(...myFloors), Math.max(...myFloors)]
    }
  }
}

onMounted(async () => {
  floorplan = await useFloorplan()
  addFilterData()
  show.value = true
})
</script>

<style lang="scss" scoped>
// @keyframes slideIn {
//   0% {
//     width: 200px;
//     height: 60px;
//   }

//   100% {
//     width: 100%;
//     height: 100vh;
//   }
// }

// @keyframes slideOut {
//   0% {
//     width: 100%;
//     height: 100vh;
//     display: block;
//     // visibility: visible;
//   }

//   100% {
//     width: 200px;
//     height: 60px;
//     display: none;
//     // visibility: hidden;
//   }
// }

.slideOut {
  visibility: hidden;
  width: 200px;
  height: 60px;
  opacity: 0;
  transition: .7s ease all;
}
.slideIn {
  transition: .7s ease all;
  display: block;
  visibility: visible;
  width: 100%;
  height: 100vh;
}
@media screen and (min-width: 768px) {
  .slideIn {
    width: 384px;
    height: 70vh;
  }
  // @keyframes slideIn {
  //   0% {
  //     width: 200px;
  //     height: 60px;
  //   }

  //   100% {
  //     width: 384px;
  //     height: 70vh;
  //   }
  // }
  // @keyframes slideOut {
  //   0% {
  //     width: 384px;
  //     height: 70vh;
  //     display: block;
  //     // visibility: visible;
  //   }

  //   100% {
  //     width: 200px;
  //     height: 60px;
  //     display: none;
  //     // visibility: hidden;
  //   }
  // }
}

.filter-container {
  // animation-duration: 0.5s;
  // animation-timing-function: ease-in-out;
}

.filter-container.slideIn {
  // animation-name: slideIn;
}

.filter-container.slideOut {
  // animation-name: slideOut;
}
</style>
