<template>
    <div class="main-container-scroll">
        <div class="scroll-container hide-scrollbar" :style="{ 'max-height': height }" @scroll="handleScroll"
            ref="scrollContainer">
            <div class="scroll-content" ref="scrollContent">
                <slot></slot>
            </div>
        </div>
        <div class="row-scrollbar" v-show="showScrollbar" :style="{ 'top': top, 'right': right }">
            <div class="scrollbar-container" :style="{ 'height': minH }" ref="scrollbarContainer">
                <div class="scrollbar" ref="scrollbar" :style="{ 'height': scrollbarH }" @mousedown="startDrag"></div>
            </div>
        </div>
    </div>
</template>

<script>
function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function convertVhToPixels(vh) {
    return window.innerHeight * (vh / 100);
}

function extractCalcValues(str) {
    const regex = /calc\((\d+)vh\s*[-+/*]\s*(\d+(?:\.\d+)?)\s*rem\)/;
    const match = str.match(regex);

    if (match) {
        const vh = convertVhToPixels(parseInt(match[1], 10))
        const val = convertRemToPixels(parseFloat(match[2]))
        return { vh, val };
    }

    return null;
}

function convertCALCToPixels(value) {
    const v = extractCalcValues(value)
    return v.vh - v.val
}

function convertToPixels(value) {
    if (value.includes('calc')) {
        return convertCALCToPixels(value);
    } else if (value.includes('rem')) {
        return convertRemToPixels(parseFloat(value));
    } else if (value.includes('vh')) {
        return convertVhToPixels(parseFloat(value));
    } else {
        return parseFloat(value);
    }
}
export default {
    name: 'CustomScrollbar',
    props: {
        h: {
            type: String,
            default: 'calc(100vh - 12.75rem)'
        },
        minH: {
            type: String,
            default: 'calc(100vh - 14rem)'
        },
        scrollbarH: {
            type: String,
            default: '100px'
        },
        top: {
            type: String,
            default: '10px'
        },
        right: {
            type: String,
            default: '-10px'
        },
    },
    data() {
        return {
            startY: 0,
            height: 'calc(100vh - 12.75rem)',
            minHeight: 'calc(100vh - 14rem)',
            startTop: 0,
            showScrollbar: false
        };
    },
    mounted() {
        this.height = this.h;
        this.minHeight = this.minH;
        const heightInPixels = convertToPixels(this.h);
        const scrollContentHeight = this.$refs.scrollContent ? this.$refs.scrollContent.clientHeight : 0;
        this.showScrollbar = scrollContentHeight > heightInPixels;
    },
    watch: {
        h: {
            handler(newValue, oldValue) {
                this.height = newValue;
                const heightInPixels = convertToPixels(newValue);
                const scrollContentHeight = this.$refs.scrollContent ? this.$refs.scrollContent.clientHeight : 0;
                this.showScrollbar = scrollContentHeight > heightInPixels;
            },
        }
    },
    updated() {
        this.$nextTick(() => {
            const heightInPixels = convertToPixels(this.h);
            const scrollContentHeight = this.$refs.scrollContent ? this.$refs.scrollContent.clientHeight : 0;
            this.showScrollbar = scrollContentHeight > heightInPixels;
        });
    },
    methods: {
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const { scrollTop, clientHeight } = scrollContainer;
            const scrollContent = this.$refs.scrollContent;
            const scrollContentHeight = scrollContent.clientHeight;
            const scrollbarHeight = this.$refs.scrollbar.offsetHeight;
            const containerHeight = this.$refs.scrollbarContainer.clientHeight;

            const maxScrollTop = scrollContentHeight - clientHeight;
            const maxScrollbarTop = containerHeight - scrollbarHeight;

            if (maxScrollTop > 0) {
                const scrollPercentage = scrollTop / maxScrollTop;
                const newTop = scrollPercentage * maxScrollbarTop;
                this.$refs.scrollbar.style.top = `${newTop}px`;
            } else {
                this.$refs.scrollbar.style.top = '0px';
            }
        },
        startDrag(e) {
            this.startY = e.clientY;
            this.startTop = parseInt(window.getComputedStyle(this.$refs.scrollbar).top, 10);

            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseup', this.stopDrag);
            e.preventDefault();
        },

        onMouseMove(e) {
            const deltaY = e.clientY - this.startY;
            // Ensure the scrollbar does not move outside the container
            const maxTop = this.$refs.scrollbarContainer.clientHeight - this.$refs.scrollbar.clientHeight;
            const newTop = Math.max(0, Math.min(maxTop, this.startTop + deltaY));

            this.$refs.scrollbar.style.top = `${newTop}px`;
            const scrollPercentage = newTop / maxTop;
            this.$refs.scrollContainer.scrollTop = scrollPercentage * (this.$refs.scrollContent.clientHeight - this.$refs.scrollContainer.clientHeight);
        },
        stopDrag() {
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('mouseup', this.stopDrag);
        }
    },
};
</script>

<style scoped>
.main-container-scroll {
    position: relative;
}

.scroll-container {
    overflow: auto;
    /* Customize as needed */
    position: relative;
}

.row-scrollbar {
    position: absolute;
    z-index: 15;
}

.scrollbar-container {
    position: relative;
    /* height: calc(100vh - 14rem); */
    /* Same as scroll-container */
    width: 3px;
    background: #D9D9D9;
    border-radius: 2px;
}

.scrollbar {
    position: absolute;
    width: 100%;
    height: 100px;
    /* Customize as needed */
    background: #7A7A7A;
    border-radius: 2px;
    cursor: pointer;
}
</style>
