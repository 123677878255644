import { defineStore } from 'pinia'

export const useWorksheets = defineStore('worksheet', {
  state: () => ({
    worksheetId: JSON.parse(localStorage.getItem('worksheetItems')) || []
  }),
  persist: true,
  getters: {
    getWorksheetId (state) {
      return state.worksheetId
    }
  },
  actions: {
    addWorksheet (data) {
      this.worksheetId = data
    },
    changeWorksheet (id, data) {
      const index = this.worksheetId.findIndex(
        (worksheet) => worksheet.Uid === id
      )
      console.log('index', index, data)
      if (index !== -1) {
        this.worksheetId[index] = data
      }
    },
    deleteWorksheet (index) {
      this.worksheetId.splice(index, 1)
    },
    toggleWorksheet (Mid, Uid) {
      const index = this.worksheetId.findIndex(
        (worksheet) => worksheet.Mid === Mid && worksheet.Uid === Uid
      )
      if (index !== -1) {
        this.worksheetId.splice(index, 1)
      } else {
        const newWorksheet = { Mid, Uid }
        this.worksheetId.push(newWorksheet)
      }
    }
  }
})
