<template>
    <div class="floorplan-top-header px-4 md:px-9 absolute z-10 w-fit">
        <floorplanHeader v-if="allData.length" mode="3d" :firstTime="true" :models="allData"
            @filterModels="getFilteredData" class="py-5 md:pt-9 md:pb-12">
        </floorplanHeader>

        <button id="arRenderUnits" class="d-none absolute"></button>
    </div>

    <div id="aframe-container">
        <video id="video" autoplay loop muted playsinline webkit-playsInline></video>

        <a-scene raycaster="objects: .clickable" cursor="rayOrigin: mouse" xrextras-gesture-detector
            xrextras-runtime-error renderer="colorManagement: true" xrweb="allowedDevices: any" class="w-full h-full">
            <a-camera id="camera" position="0 8 8" raycaster="objects: .cantap" cursor="fuse: false; rayOrigin: mouse;"
                look-controls="touchEnabled:false;pointerLockEnabled:true">
            </a-camera>

            <a-entity light="
                type: directional;
                intensity: 0.8;
                castShadow: true;
                shadowMapHeight:4200;
                shadowMapWidth:4200;
                shadowCameraTop: 10;
                target: #model-entity;
                shadowRadius: 12" xrextras-attach="target: model-entity; offset: 1 15 3;" shadow>
            </a-entity>

            <a-light type="ambient" intensity="0.7"></a-light>

            <a-entity id="model-entity" :gltf-model="gltfSrc" class="cantap" xrextras-hold-drag
                xrextras-two-finger-rotate xrextras-pinch-scale modify-materials scale="0.1 0.1 0.1"
                shadow="receive: false">
            </a-entity>

            <a-plane id="ground" rotation="-90 0 0" width="1000" height="1000" material="shader: shadow" shadow>
            </a-plane>

        </a-scene>
    </div>

    <div class="main-3d detail-popup">
        <modelDetailPopup :hoverdUntiDetail="hoverdUntiDetail" @close="closePopup()" :closeable="true"
            v-show="hoverdUntiDetail && Object.keys(hoverdUntiDetail).length" />
    </div>
</template>

<script>
import { useFloorplan } from "@/store/floor.js";
import floorplanHeader from "@/components/floorplans/floorplanHeader.vue";
import modelDetailPopup from "@/components/floorplans/modelDetailPopup.vue";
import { floors } from "@/services/axios/floors.service.js";

export default {
    name: 'MyComponent',

    components: {
        floorplanHeader,
        modelDetailPopup
    },

    data() {
        return {
            gltfSrc: '/Box.glb',
            allData: [],
            filteredModels: [],
            allUnits: [],
            allUnitsName: [],
            hoverdUntiDetail: "",
        };
    },

    async mounted() {
        const $this = this;
        AFRAME.registerComponent('modify-materials', {
            init: function () {
                this.el.addEventListener('model-loaded', async () => {
                    await $this.getModelsData();
                    const $elThis = this;

                    document.getElementById("arRenderUnits").addEventListener("click", resetUnits);
                    function resetUnits() {
                        const obj = $elThis.el.getObject3D('mesh');
                        obj.traverse(node => {
                            if (node.isMesh && node.name.indexOf('Geometry') >= 0) {
                                node.material.color.set('blue');
                                const nodeName = node.name.split("Geometry")[1];
                                if ($this.allUnitsName.indexOf(nodeName) >= 0 || $this.allUnitsName.indexOf(nodeName.split('_1')[0]) >= 0) {
                                    node.material.color.set('green');
                                    $elThis.addClickListener(node);
                                }
                            }
                        });
                    }

                    resetUnits();
                });
            },

            addClickListener: function (mesh) {
                mesh.el = this.el;

                mesh.el.sceneEl.addEventListener('click', (evt) => {
                    const clickedMesh = evt.detail.intersection && evt.detail.intersection.object;
                    if (clickedMesh === mesh) {
                        const nodeName = mesh.name.split("Geometry")[1];
                        const hoverdUntiName = nodeName.split('_1')[0];
                        if (hoverdUntiName) {
                            $this.hoverdUntiDetail =
                                $this.allUnits.find((x) => x.box_name == hoverdUntiName) || "";
                            mesh.material.color.set('red');
                        }
                    }
                });
            }

        });

        await this.timeout(1000);
        setTimeout(async () => {
            await this.timeout(500);
            const video = document.getElementById('video');
            const constraints = {
                video: {
                    facingMode: { exact: "environment" }
                }
            };
            navigator.mediaDevices.getUserMedia(constraints)
                .then(async (stream) => {
                    await this.timeout(500);
                    video.srcObject = stream;
                })
                .catch((error) => {
                    console.error('Error accessing the camera: ', error);
                });
        }, 200);
    },

    methods: {
        convertFloorStringsToNumbers(data) {
            return data.map(item => {
                const floors = item.floors.map(floorString => parseInt(floorString));
                return {
                    ...item, // Copy all other properties
                    floors, // Update floors with converted numbers
                };
            });
        },

        async getModelsData() {
            try {
                const floorplan = useFloorplan();
                let res;
                res = await floors();
                floorplan.addFloorplan(res.data);
                this.allData = this.convertFloorStringsToNumbers(floorplan.floorplanList);
                this.filteredModels = this.allData;
                this.setData(this.filteredModels);
            } catch (error) {
                console.log(error);
            }
        },

        getFilteredData(models) {
            this.filteredModels = models;
            this.setData(this.filteredModels);

            document.getElementById("arRenderUnits").click();
        },

        getAllUnitsWithParentData(data) {
            const result = [];

            data.forEach((parent) => {
                parent.units.forEach((unit) => {
                    const combinedUnit = {
                        ...parent,
                        ...unit,
                    };
                    delete combinedUnit.units;
                    result.push(combinedUnit);
                });
            });
            return result;
        },

        closePopup() {
            document.getElementById("arRenderUnits").click();
            this.hoverdUntiDetail = {};
        },

        setData(data) {
            this.allUnits = this.getAllUnitsWithParentData(data);
            // eslint-disable-next-line camelcase, array-callback-return
            this.allUnitsName = this.allUnits.map(({ box_name, status }) => {
                if (status !== "Conditional") {
                    // eslint-disable-next-line camelcase
                    return box_name;
                }
            });
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
};
</script>

<style lang="scss">
/* Add your styles here */
#aframe-container {
    width: 100%;
    height: 100%;

    a-scene {
        width: 100%;
        height: 100vh;
        display: block;
    }

    .a-canvas {
        top: 6rem !important;
    }

    #video {
        position: absolute;
        height: calc(100vh - 5.8rem);
        max-width: unset;
        top: 6rem;
        left: 0;
    }
}

.detail-popup {
    .box-modal {
        width: 100%;
        height: 100% !important;
        top: 0;
        left: 0;
        z-index: 999;
    }
}
</style>