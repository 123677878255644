import { defineStore } from "pinia";

export const useVerticalMenu = defineStore({
  id: "verticalMenu",
  state: () => ({
    isOpen: false,
  }),
  actions: {
    setIsOpen(val) {
        this.isOpen = val;
    }
  },
});
