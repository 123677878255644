import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TestView from "../views/TestView.vue";
import { useCookies } from "vue3-cookies";
import { event } from "vue-gtag";
// import { startTracking, stopTracking } from "@/util/time-tracker";

const { cookies } = useCookies();

function lazyLoad(view) {
  return () => import(`../views/${view}.vue`);
}

const force = process.env.VUE_APP_MANDATORY_LOGIN;
const checkAuth = (to, from, next) => {
  if (force == "true") {
    const isAuthenticated = cookies.get("token");
    // console.log('to:', to, '\nfrom:', from, '\nnext', next)
    if (to.name === "home" && isAuthenticated) {
      next("/floorplans?full_3d=true");
    }
    if (to.meta.requiresAuth && !isAuthenticated) {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.name === "home") {
      next("/floorplans?full_3d=true");
    } else {
      next();
    }
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    beforeEnter: checkAuth,
    meta: { title: "Login" },
    component: HomeView,
  },
  {
    path: "/test",
    name: "test",
    // beforeEnter: checkAuth,
    meta: { layout: "default", requiresAuth: true, has3dModel: false },
    component: TestView,
  },
  {
    path: "/Form",
    name: "FormView",
    meta: { layout: "default", requiresAuth: true, has3dModel: false },
    beforeEnter: checkAuth,
    component: () =>
      import(/* webpackChunkName: "FormView" */ "../views/FormView.vue"),
  },
  {
    path: "/floorplans",
    name: "floorplans",
    component: lazyLoad("floorplan/FloorplansPage"),
    beforeEnter: checkAuth,
    meta: { layout: "default", title: "Floorplans", requiresAuth: true },
    props: true,
  },
  {
    path: "/developer",
    name: "developer",
    component: lazyLoad("developer/developerPage"),
    beforeEnter: checkAuth,
    meta: { layout: "default", requiresAuth: true },
    props: true,
  },

  {
    path: "/marketing",
    name: "marketingMedia",
    component: lazyLoad("marketingMedia/marketingMedia"),
    beforeEnter: checkAuth,
    meta: { layout: "default", requiresAuth: true },
    props: true,
  },
  {
    path: "/favourites",
    name: "favouritesPage",
    component: lazyLoad("favourites/favouritesPage"),
    beforeEnter: checkAuth,
    meta: { layout: "default", title: "Favourites", requiresAuth: true },
    props: true,
  },
  {
    path: "/compare",
    name: "comparePage",
    component: lazyLoad("compare/comparePage"),
    beforeEnter: checkAuth,
    meta: { layout: "default", title: "Compare", requiresAuth: true },
    props: true,
  },
  {
    path: "/contactus",
    name: "contactUs",
    component: lazyLoad("contactUs/contactUs"),
    beforeEnter: checkAuth,
    meta: { layout: "default", requiresAuth: true },
    props: true,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: lazyLoad("gallery/galleryPage"),
    beforeEnter: checkAuth,
    meta: { layout: "default", title: "Gallery", requiresAuth: true },
    props: true,
  },
  {
    path: "/neighbourhood",
    name: "Neighbourhood-page",
    beforeEnter: checkAuth,
    component: lazyLoad("neighbourhood/NeighbourhoodView"),
    meta: { layout: "default", title: "Neighbourhood", requiresAuth: true },
    props: true,
  },
  {
    path: "/builderstory",
    name: "builderstory-page",
    beforeEnter: checkAuth,
    component: lazyLoad("builderStory/builderStoryPage"),
    meta: { layout: "default", title: "Builder Story", requiresAuth: true },
    props: true,
  },
  {
    path: "/broker-package",
    name: "broker-package-page",
    beforeEnter: checkAuth,
    component: lazyLoad("brokerPackage/brokerPackagePage"),
    meta: { layout: "default", title: "Broker Package", requiresAuth: true },
    props: true,
  },
];

const showWorksheetRoute = process.env.VUE_APP_SHOW_WORKSHEET;

const worksheetRoute = {
  path: "/worksheet",
  name: "worksheet",
  component: lazyLoad("worksheet/worksheetPage"),
  beforeEnter: checkAuth,
  meta: { layout: "default", title: "Worksheet", requiresAuth: true },
  props: true,
};

if (+showWorksheetRoute) {
  routes.push(worksheetRoute);
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.afterEach((to, from) => {
  // window.addEventListener('beforeunload', () => localStorage.setItem("close", 555));
// })

router.beforeEach((to, from) => {
  let toRouteTitle = to.meta.title;
  if (to.meta.title === "Floorplans") {
    if (to.query.full_3d == "true") {
      toRouteTitle = "Building";
    } else if (to.query.full_3d == "false") {
      toRouteTitle = "Floorplans";
    }
  }

  // let fromRouteTitle = from.meta.title;
  // if (from.meta.title === "Floorplans") {
  //   if (from.query.full_3d == "true") {
  //     fromRouteTitle = "Building";
  //   } else if (from.query.full_3d == "false") {
  //     fromRouteTitle = "Floorplans";
  //   }
  // }

  // if (fromRouteTitle) {
  //   stopTracking(fromRouteTitle);
  // }
  // startTracking();

  const email = localStorage.getItem("email");
  const projectId = localStorage.getItem("project_id");
  const organizationId = localStorage.getItem("organization_id");
  const status = localStorage.getItem("status");
  const userType = localStorage.getItem("user_type");

  if (toRouteTitle && email && projectId && organizationId) {
    event("page_view", {
      organization_id: organizationId,
      project_id: projectId,
      email: email,
      status: status,
      user_type: userType,
      route_title: toRouteTitle,
    });
  }
});

export default router;
