<template>
  <div class="fixed inset-0 w-full h-full z-150 backdrop-blur-sm bg-black/60">
    <div class="bg-white relative h-full w-[346px] flex flex-col">
      <div class="flex items-center justify-between px-9 py-[18px]">
        <button @click="$emit('closeSidebar')"
          class="cursor-pointer rounded-full w-[60px] h-[60px] flex justify-center items-center border border-[#DBDBDB]">
          <div class="w-6 h-6 flex flex-col justify-center items-center gap-1">
            <span
              class="transition-all w-[26px] block rotate-45 origin-[9px_4px] h-0.5 rounded-md bg-mainBlack-1000"></span>
            <!-- <span
              class="transition-all w-[26px] block h-0.5 rounded-md bg-mainBlack-1000"
            ></span> -->
            <span
              class="transition-all -rotate-45 origin-[9px_-1px] w-[26px] block h-0.5 rounded-md bg-mainBlack-1000"></span>
          </div>
        </button>
        <img src="/lakeshore-logo.png" alt="Lakeshore Logo" class="w-[102px]" />
      </div>
      <hr class="border-t-[#DBDBDB] mb-10" />
      <custom-scrollbar :scrollbarH="150 + 'px'" :right="'10px'" :h="'calc(100vh - 9rem)'">

      <div class="grow px-12 flex flex-col min-h-[calc(100vh_-_150px)] justify-between">
        <ul class="mb- mt-6">
          <li v-for="link in routes" :key="link.label"
            class="4xl:pb-12 lg:pb-9 xl:pb-10 pb-8 text-[18px] 4xl:text-lg font-medium">
            <router-link :to="link.route" :exact-active-class="getActiveClass(link.label)" class="text-qooBlack-6"
              @click="$emit('closeSidebar')">
              {{ link.label }}
            </router-link>
          </li>
        </ul>
        <div class="flex flex-col gap-3 items-center mt-5 pb-14">
          <ul style="
              width: 100%;
              list-style-type: disc;
              padding-left: 16px;">
            <li @click="$emit('closeSidebar')" class="mb-4">
              <router-link class="text-qooBlack-6 text-[18px] 4xl:text-lg font-medium" :to="{ name: 'favouritesPage' }">
                Favourites
              </router-link>
            </li>
            <li @click="$emit('closeSidebar')">
              <router-link class="text-qooBlack-6 text-[18px] 4xl:text-lg font-medium" :to="{ name: 'worksheet' }">
                Submit Worksheet
              </router-link>
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-3 mt-5 mb-[82px]">
          <p class="text-qooBlack-6 text-[18px] 4xl:text-lg font-medium flex gap-2 cursor-pointer items-center justify-start"
            @click="logout">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M15.24 22.2705H15.11C10.67 22.2705 8.53002 20.5205 8.16002 16.6005C8.12002 16.1905 8.42002 15.8205 8.84002 15.7805C9.24002 15.7405 9.62002 16.0505 9.66002 16.4605C9.95002 19.6005 11.43 20.7705 15.12 20.7705H15.25C19.32 20.7705 20.76 19.3305 20.76 15.2605V8.74047C20.76 4.67047 19.32 3.23047 15.25 3.23047H15.12C11.41 3.23047 9.93002 4.42047 9.66002 7.62047C9.61002 8.03047 9.26002 8.34047 8.84002 8.30047C8.42002 8.27047 8.12001 7.90047 8.15001 7.49047C8.49001 3.51047 10.64 1.73047 15.11 1.73047H15.24C20.15 1.73047 22.25 3.83047 22.25 8.74047V15.2605C22.25 20.1705 20.15 22.2705 15.24 22.2705Z"
                fill="#7A7A7A" />
              <path
                d="M15 12.75H3.62C3.21 12.75 2.87 12.41 2.87 12C2.87 11.59 3.21 11.25 3.62 11.25H15C15.41 11.25 15.75 11.59 15.75 12C15.75 12.41 15.41 12.75 15 12.75Z"
                fill="#7A7A7A" />
              <path
                d="M5.84994 16.0998C5.65994 16.0998 5.46994 16.0298 5.31994 15.8798L1.96994 12.5298C1.67994 12.2398 1.67994 11.7598 1.96994 11.4698L5.31994 8.11984C5.60994 7.82984 6.08994 7.82984 6.37994 8.11984C6.66994 8.40984 6.66994 8.88984 6.37994 9.17984L3.55994 11.9998L6.37994 14.8198C6.66994 15.1098 6.66994 15.5898 6.37994 15.8798C6.23994 16.0298 6.03994 16.0998 5.84994 16.0998Z"
                fill="#7A7A7A" />
            </svg>
            <span class="">log out</span>
          </p>
        </div>
      </div>
      </custom-scrollbar>
      <div v-if="store.isAgent" class="absolute bottom-0 left-0 z-20 w-full h-[72px] border-t border-qooWhite-3 bg-white">
        <div class="flex h-full justify-center items-center gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12.9999 11.7483C12.8099 11.7483 12.6199 11.6783 12.4699 11.5283C12.1799 11.2383 12.1799 10.7583 12.4699 10.4683L20.6699 2.26828C20.9599 1.97828 21.4399 1.97828 21.7299 2.26828C22.0199 2.55828 22.0199 3.03828 21.7299 3.32828L13.5299 11.5283C13.3799 11.6783 13.1899 11.7483 12.9999 11.7483Z"
              fill="#3D3D3D" />
            <path
              d="M22 7.55C21.59 7.55 21.25 7.21 21.25 6.8V2.75H17.2C16.79 2.75 16.45 2.41 16.45 2C16.45 1.59 16.79 1.25 17.2 1.25H22C22.41 1.25 22.75 1.59 22.75 2V6.8C22.75 7.21 22.41 7.55 22 7.55Z"
              fill="#3D3D3D" />
            <path
              d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
              fill="#3D3D3D" />
          </svg>
          <p class="text-qooBlack-5 text-[18px] 4xl:text-lg font-medium underline">
            Open QOO Agent Panel
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useCookies } from 'vue3-cookies'
import CustomScrollbar from '@/components/base/CustomScrollbar.vue';
import { useStore } from '@/store/loading.js'

const store = useStore()
const { cookies } = useCookies()
defineEmits(["closeSidebar"]);

const routes = [
  {
    label: "Building",
    route: { name: "floorplans", query: { full_3d: true } },
  },
  {
    label: "Image Sequence",
    route: { name: "test" },
  },
  {
    label: "Floorplans",
    route: { name: "floorplans", query: { full_3d: false } },
  },
  {
    label: "Neighbourhood",
    route: { name: "Neighbourhood-page" },
  },
  {
    label: "Gallery",
    route: { name: "gallery" },
  },
  {
    label: "Builder Story",
    route: { name: "builderstory-page" },
  },
  {
    label: "Broker Package",
    route: { name: "broker-package-page" },
  },
];

const route = useRoute();

// const activeBuilding = computed(() => {
//   return route.query.full_3d === "true";
// });

const logout = () => {
  cookies.remove('token');
  localStorage.removeItem('user');
  location.reload();
}

function getActiveClass(label) {
  console.log("route.query.compare", route.query.compare)
  if (route.query.compare === "true") {
    return
  }
  if (route.query.full_3d) {
    if (route.query.full_3d === "true" && label === "Building") {
      return "active-route";
    } else if (route.query.full_3d === "false" && label === "Floorplans") {
      return "active-route";
    }
  } else {
    return "active-route";
  }
}
</script>

<style lang="scss" scoped>
.active-route {
  color: #000;
  position: relative;
}

.active-route::before {
  content: "";
  position: absolute;
  height: 36px;
  width: 4px;
  background-color: #2460e6;
  border-radius: 18px;
  left: -14px;
  top: -4px;
}
</style>
