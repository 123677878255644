<template>
  <div class="mt-3" v-if="props.item && props.item.data[0] < props.item.data[1]">
      <div
          class="bg-white/10 flex w-full md:max-w-[336px] flex-col max-h-[15rem] items-stretch pl-3 pr-2 py-5 overflow-hidden rounded-2xl hide-scrollbar transition-all duration-300 ease-in">
          <div class="flex w-full items-stretch justify-between gap-5">
              <div class="flex items-center justify-between gap-2">
                  <div
                      class="border bg-transparent flex w-4 shrink-0 h-4 flex-col rounded-md border-solid border-op-white-100">
                  </div>
                  <div class="text-op-white-800 text-sm self-center my-auto">{{ props.item.name }}</div>
              </div>
              <div class="flex gap-1 pr-1">
                  <p class="text-white text-sm">
                      {{ sBarMinValue }}
                  </p>
                  <p class="text-white text-sm">-</p>
                  <p class="text-white text-sm">
                      {{ sBarMaxValue }}
                  </p>
              </div>
          </div>
          <div class="flex flex-col items-stretch overflow-auto hide-scrollbar">
              <div class="MultiRangeSliderContainer">
                  <MultiRangeSlider :min="props.item.data[0]" :max="props.item.data[1]" :min-value="sBarMinValue"
                      :max-value="sBarMaxValue" :ruler="false" :step="1" :range-margin="1" @input="updateBarValues" />
              </div>
          </div>
      </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, onMounted } from 'vue'
import MultiRangeSlider from 'multi-range-slider-vue'
import 'multi-range-slider-vue/MultiRangeSliderBlack.css'
import 'multi-range-slider-vue/MultiRangeSliderBarOnly.css'
import useEmitter from '@/composable/useEmitter'
const emitter = useEmitter()

const sBarMinValue = ref(0)
const sBarMaxValue = ref(100)

const props = defineProps({
item: {
  type: Object
},
text: {
  type: String,
  default: 'button'
},
keyName: {
  type: String,
  default: 'button'
},
classList: {
  type: [String],
  default: 'px-9'
}
})

onMounted(() => {
sBarMinValue.value = props.item.data[0]
sBarMaxValue.value = props.item.data[1]
})

function debounce(func, wait) {
let timeout;

return function executedFunction(...args) {
  const later = () => {
    clearTimeout(timeout);
    func(...args);
  };

  clearTimeout(timeout);
  timeout = setTimeout(later, wait);
};
}

const emit = defineEmits(['updated'])

const updateBarValuesDebounced = debounce((newValues) => {
emit('updated', {
  name: props.keyName,
  data: newValues  
})
}, 700)

const updateBarValues = (e) => {
sBarMinValue.value = e.minValue
sBarMaxValue.value = e.maxValue
const newValues = [e.minValue, e.maxValue]
updateBarValuesDebounced(newValues) 
}

</script>
